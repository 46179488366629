<template>
  <div class="container-fluid privacy-policy">
    <div class="row">
      <div class="col-12 col-md-8 offset-md-2 pt-5">
          <div v-if="pageContent" v-html="pageContent"></div>
          <div v-else>{{  $store.state.translation.el_privacy_policy_soon}}</div>
      </div>
    </div>
    
  </div>

</template>

<script>
export default {
  name: "PrivacyPolicy",
  data(){
    return{
      pageContent: null
    }
  },
  methods:{
    async getCurrentPageContent(){
      let _this = this
      const content = await _this.getPage('3')    
      _this.pageContent = content[0].description  
    },
  },
  created() {
    this.getCurrentPageContent()
  }
}
</script>
